<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="vehicle">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Agregar horario</span>
                    </v-row>
                </v-col>
            </v-row>

            <validation-observer ref="form">
                <v-form>
                    <v-row>
                        <v-col cols="12">
                            <validation-provider name="date" v-slot="{ errors }" rules="required">
                                <v-menu
                                    v-model="dateMenu"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="Fecha"
                                            color="accent"
                                            v-model="data.date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </template>

                                    <v-date-picker
                                        v-model="data.date"
                                        no-title
                                        :first-day-of-week="1"
                                        locale="es-AR"
                                        event-color="green lighten-1"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </validation-provider>
                        </v-col>

                        <v-col cols="12">
                            <v-switch
                                v-model="data.repeatable"
                                label="Repetir semanalmente"
                            ></v-switch>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <validation-provider name="start" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.start"
                                    v-mask="maskTime"
                                    label="Desde"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                    dense
                                />
                            </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <validation-provider name="end" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.end"
                                    v-mask="maskTime"
                                    label="Hasta"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                    dense
                                />
                            </validation-provider>
                        </v-col>

                        <v-col cols="12">
                            <validation-provider name="startLocation" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="startLocationSearch"
                                    label="Punto de Partida"
                                    :hint="startLocationHint"
                                    persistent-hint
                                    :error-messages="errors"
                                    dense
                                >
                                    <template v-slot:append-outer>
                                        <v-btn color="primary" @click="onStartSearchSelected">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </validation-provider>
                        </v-col>

                        <v-col cols="12">
                            <validation-provider name="endLocation" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="endLocationSearch"
                                    label="Punto de Llegada"
                                    :hint="endLocationHint"
                                    persistent-hint
                                    :error-messages="errors"
                                    dense
                                >
                                    <template v-slot:append-outer>
                                        <v-btn color="primary" @click="onEndSearchSelected">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-form>
            </validation-observer>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isLoading">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    /* global google */

    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            vehicle: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                active: false,
                dateMenu: false,

                data: {
                    date: null,
                    repeatable: false,
                    startLocation: null,
                    endLocation: null
                },

                maskTime: '##:##',
                startLocationSearch: '',
                startLocationHint: '',
                endLocationSearch: '',
                endLocationHint: ''
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['vehicles/isLoading']();
            },

            schedules() {
                return this.vehicle.schedule;
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        mounted() {
            this.geocoder = new google.maps.Geocoder();
        },

        methods: {
            onBackSelected() {
                this.active = false;
            },

            async onStartSearchSelected() {
                let bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(-35.068078, -59.219226),
                    new google.maps.LatLng(-34.260481, -57.673578)
                );

                try {
                    let result = await this.getAddressInfo({
                        address: this.startLocationSearch,
                        bounds: bounds,
                        componentRestrictions: { country: 'ar' }
                    });

                    let location = result.geometry.location;
                    this.data.startLocation = location;

                    this.startLocationSearch = result.formatted_address;
                    this.startLocationHint = result.geometry.location.lat() + ', ' + result.geometry.location.lng();
                }
                catch (err) {
                    console.error(err);
                }
            },

            async onEndSearchSelected() {
                let bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(-35.068078, -59.219226),
                    new google.maps.LatLng(-34.260481, -57.673578)
                );

                try {
                    let result = await this.getAddressInfo({
                        address: this.endLocationSearch,
                        bounds: bounds,
                        componentRestrictions: { country: 'ar' }
                    });

                    let location = result.geometry.location;
                    this.data.endLocation = location;

                    this.endLocationSearch = result.formatted_address;
                    this.endLocationHint = result.geometry.location.lat() + ', ' + result.geometry.location.lng();
                }
                catch (err) {
                    console.error(err);
                }
            },

            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    await this.$store.dispatch('vehicles/addSchedule', {
                        vehicle: this.vehicle,
                        data: {
                            type: 'type.work',
                            ...this.data
                        }
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Cambios guardados');

                    this.data = {
                        date: null,
                        repeatable: false,
                        startLocation: null,
                        endLocation: null
                    };

                    this.startLocationSearch = '';
                    this.startLocationHint = '';
                    this.endLocationSearch = '';
                    this.endLocationHint = '';

                    this.$refs.form.reset();

                    this.active = false;
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    if(err.code !== '') {
                        formErrors.date = this.$t(err.code);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            async getAddressInfo(params) {
                return new Promise((resolve, reject) => {
                    this.geocoder.geocode(params, (results, status) => {
                        if(status !== 'OK') {
                            return reject(new Error());
                        }

                        return resolve(results[0]);
                    });
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>

<template>
    <template-layout>
        <template slot="drawers">
            <vehicle-schedule-create-drawer
                v-model="showCreateDrawer"
                :vehicle="vehicle"
            />

            <vehicle-schedule-create-break-drawer
                v-model="showCreateBreakDrawer"
                :vehicle="vehicle"
            />

            <vehicle-schedule-view-drawer
                v-model="showScheduleDrawer"
                :vehicle="vehicle"
                :schedule="selectedSchedule"
            />
        </template>

        <template slot="content">
            <v-container v-if="vehicle">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline" v-if="vehicle">Cronograma {{ vehicle.name }}</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn fab x-small color="primary" class="elevation-0" @click.stop="onNewSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                            </template>
                            <span>Agregar Horario</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ml-4" icon @click.stop="onNewBreakSelected" v-on="on"><v-icon>mdi-coffee</v-icon></v-btn>
                            </template>
                            <span>Agregar descanso</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-toolbar flat>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="onPrevSelected"
                                >
                                    <v-icon small>
                                    mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="onNextSelected"
                                >
                                    <v-icon small>
                                    mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                            </v-toolbar>

                            <v-card-text>
                                <v-calendar
                                    v-model="value"
                                    first-interval="6"
                                    interval-count="16"
                                    interval-height="30"
                                    ref="calendar"
                                    :events="events"
                                    event-overlap-mode="stack"
                                    color="primary"
                                    type="week"
                                    @change="updateRange"
                                    @click:event="onScheduleSelected"
                                ></v-calendar>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import VehicleScheduleCreateDrawer from '@/pages/Admin/Vehicles/VehicleScheduleCreateDrawer';
    import VehicleScheduleCreateBreakDrawer from '@/pages/Admin/Vehicles/VehicleScheduleCreateBreakDrawer';
    import VehicleScheduleViewDrawer from '@/pages/Admin/Vehicles/VehicleScheduleViewDrawer';

    export default {
        components: {
            TemplateLayout,
            VehicleScheduleCreateDrawer,
            VehicleScheduleCreateBreakDrawer,
            VehicleScheduleViewDrawer
        },

        data() {
            return {
                events: [],

                value: '',
                calendarRange: {},

                showCreateDrawer: false,
                showCreateBreakDrawer: false,
                showScheduleDrawer: false,
                selectedSchedule: null,

                headers: [
                    { text: 'Nombre', value: 'name' },
                    { text: 'Marca', value: 'makeAndModel' },
                    { text: 'Patente', value: 'license' },
                    { text: 'Conductor', value: 'driver' },
                    { text: 'Acciones', value: 'actions', align: 'end' }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['vehicles/isLoading']();
            },

            vehicle() {
                let id = parseInt(this.$route.params.id);
                return this.$store.getters['vehicles/getById'](id);
            }
        },

        watch: {
            vehicle: {
                deep: true,
                handler(v) {
                    this.refreshEvents();
                }
            },

            showEditDrawer(val) {
                if(val) {
                    this.showCreateDrawer = false;
                }
            },

            showCreateDrawer(val) {
                if(val) {
                    this.showEditDrawer = false;
                }
            }
        },

        async created() {
            let id = parseInt(this.$route.params.id);

            await this.$store.dispatch('vehicles/fetchOne', { id: id });
        },

        methods: {
            async updateRange({ start, end }) {
                this.calendarRange = {
                    start: start,
                    end: end
                };

                if(!this.vehicle) {
                    return;
                }

                const startDate = this.$moment(start.date).set({ hour: 0, minute: 0, second: 0 });
                const endDate = this.$moment(start.date).add(7, 'days').set({ hour: 23, minute: 59, second: 59 });

                await this.$store.dispatch('vehicles/fetchSchedule', {
                    vehicle: this.vehicle,
                    filters: {
                        date: {
                            lte: endDate.toISOString(),
                            gte: startDate.toISOString()
                        }
                    }
                });
            },

            onPrevSelected() {
                this.$refs.calendar.prev();
            },

            onNextSelected() {
                this.$refs.calendar.next();
            },

            onNewSelected() {
                this.showCreateDrawer = true;
            },

            onNewBreakSelected() {
                this.showCreateBreakDrawer = true;
            },

            onScheduleSelected(event) {
                this.showScheduleDrawer = true;
                this.selectedSchedule = event.event.schedule;
            },

            refreshEvents() {
                const events = [];

                if(this.vehicle && this.vehicle.schedule) {
                    for(let s of this.vehicle.schedule) {
                        let startSplit = s.start.split(':');
                        let endSplit = s.end.split(':');

                        let ms = this.$moment(s.date).hour(startSplit[0]).minute(startSplit[1]);
                        let me = this.$moment(s.date).hour(endSplit[0]).minute(endSplit[1]);

                        let evt = null;
                        if(s.type === 'type.work') {
                            evt = {
                                name: 'Horario de Trabajo',
                                start: ms.format('YYYY-M-D H:m'),
                                end: me.format('YYYY-M-D H:m'),
                                color: s.repeatable ? 'green' : 'blue',
                                schedule: s
                            };
                        }
                        else if(s.type === 'type.break') {
                            evt = {
                                name: 'Descanso',
                                start: ms.format('YYYY-M-D H:m'),
                                end: me.format('YYYY-M-D H:m'),
                                color: 'orange',
                                schedule: s
                            };
                        }

                        events.push(evt);
                    }
                }

                this.events = events;
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>

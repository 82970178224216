<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="vehicle">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Agregar descanso</span>
                    </v-row>
                </v-col>
            </v-row>

            <validation-observer ref="form">
                <v-form>
                    <v-row>
                        <v-col cols="12">
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        label="Fecha"
                                        color="accent"
                                        v-model="data.date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="data.date"
                                    no-title
                                    :first-day-of-week="1"
                                    locale="es-AR"
                                    event-color="green lighten-1"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <validation-provider name="start" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.start"
                                    v-mask="maskTime"
                                    label="Desde"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                    dense
                                />
                            </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <validation-provider name="end" v-slot="{ errors }" rules="required">
                                <v-text-field
                                    v-model="data.end"
                                    v-mask="maskTime"
                                    label="Hasta"
                                    append-icon="mdi-clock-outline"
                                    :error-messages="errors"
                                    dense
                                />
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-form>
            </validation-observer>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isLoading">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            vehicle: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                active: false,

                dateMenu: false,

                data: {
                    date: null
                },

                maskTime: '##:##'
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['vehicles/isLoading']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        mounted() {
        },

        methods: {
            onBackSelected() {
                this.active = false;
            },

            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    await this.$store.dispatch('vehicles/addSchedule', {
                        vehicle: this.vehicle,
                        data: {
                            type: 'type.break',
                            repeatable: false,
                            ...this.data
                        }
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Cambios guardados');

                    this.data = {
                        day: 1,
                        startLocation: null,
                        endLocation: null
                    };

                    this.$refs.form.reset();

                    this.active = false;
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>

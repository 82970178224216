<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="schedule">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Horario</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>Tipo</v-list-item-content>
                            <v-list-item-content>{{ getScheduleTypeName(schedule.type) }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>Fecha</v-list-item-content>
                            <v-list-item-content>{{ getScheduleDateName(schedule) }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>Horario</v-list-item-content>
                            <v-list-item-content>{{ schedule.start }} - {{ schedule.end }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="red" dark block @click="onDeleteSelected" :loading="isLoading">Eliminar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            vehicle: {
                type: Object,
                default: null
            },

            schedule: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                active: false
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['vehicles/isLoading']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        mounted() {
        },

        methods: {
            getScheduleTypeName(type) {
                switch (type) {
                case 'type.work':
                    return 'Trabajo';
                case 'type.break':
                    return 'Descanso';
                default:
                    return 'Desconocido';
                }
            },

            getScheduleDateName(schedule) {
                if(schedule.repeatable) {
                    return this.$moment().day(schedule.day).format('[Todos los] dddd');
                }
                else {
                    return this.$moment(schedule.date).format('D/M/YYYY');
                }
            },

            onBackSelected() {
                this.active = false;
            },

            async onDeleteSelected() {
                try {
                    await this.$store.dispatch('vehicles/deleteSchedule', {
                        vehicle: this.vehicle,
                        schedule: this.schedule
                    });

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    
</style>
